import { v4 as uuid } from 'uuid';
import { parseUrl } from './utils/url';
import { isDefined } from './utils/helpers';
import type { ParsedUrl } from './declarations';

export class PageView {
  /**
   * Current "Visit/Page View Id"
   */
  id: string;
  /**
   * Time to Live. Used to determine if a new page view
   * should be created via expire inactivity
   */
  ttl: number;
  /**
   * Total Time Spent on the current page
   */
  timeOnPage: number;
  /**
   * Current Window State
   */
  windowState: 'active' | 'inactive';
  /**
   * Time the page view was created / first entered
   */
  createdAt: number;
  /**
   * Time the window state changed to 'active'
   */
  becameActiveAt: number;
  /**
   * Time the window state changed to 'inactive'
   */
  becameInactiveAt: number | null;
  /**
   * Current URL
   */
  url: ParsedUrl;

  constructor(createdAt?: number) {
    this.id = uuid();
    this.url = parseUrl() as ParsedUrl;
    this.timeOnPage = 0;
    this.windowState = 'active';
    this.createdAt = createdAt ?? new Date().getTime();
    this.becameActiveAt = new Date().getTime();
    this.becameInactiveAt = null;
    this.ttl = this.extendTTL();
  }

  get validTTL(): boolean {
    return new Date().getTime() < this.ttl;
  }

  setWindowState(state: 'active' | 'inactive'): void {
    if (state === 'active') {
      this.becameActiveAt = new Date().getTime();
      this.becameInactiveAt = null;
      this.extendTTL();
    }

    if (state === 'inactive') {
      this.becameInactiveAt = new Date().getTime();
      this.increaseTimeOnPage(this.becameInactiveAt - this.becameActiveAt);
    }

    this.windowState = state;
  }

  increaseTimeOnPage(amount: number): void {
    if (!isDefined(amount)) {
      return;
    }
    if (amount < 0) {
      amount = amount * -1;
    }
    this.timeOnPage += amount;
  }

  extendTTL(): number {
    return (this.ttl = new Date().getTime() + 15 * 1000 * 60);
  }
}
