import { LifeCyclePageEvent } from './declarations';

type ActivityObserverFunction = () => void;
/**
 * The `UserActivity` class tracks user activity and
 * alerts observers when the user goes inactive.
 */
export class UserActivity {
  private observers: ActivityObserverFunction[] = [];
  private timeoutId: ReturnType<typeof setTimeout> | null = null;

  constructor(private interval: number) {
    this.reset = this.reset.bind(this);
  }

  watch(observer: ActivityObserverFunction) {
    this.observers.push(observer);
  }

  unwatch(observer: ActivityObserverFunction) {
    this.observers = this.observers.filter((obs) => obs !== observer);
  }

  start() {
    if (this.timeoutId === null) {
      document.addEventListener('scroll', this.reset);
      document.addEventListener('mousemove', this.reset);
      document.addEventListener('click', this.reset);
      document.addEventListener('dblclick', this.reset);
      document.addEventListener('input', this.reset);
    }

    this.timeoutId = setTimeout(() => {
      this.observers.forEach((observe) => observe());
    }, this.interval);
  }

  reset() {
    this.start();
  }

  stop() {
    if (this.timeoutId !== null) {
      clearInterval(this.timeoutId);
      this.timeoutId = null;
    }

    document.removeEventListener('scroll', this.reset);
    document.removeEventListener('mousemove', this.reset);
    document.removeEventListener('click', this.reset);
    document.removeEventListener('dblclick', this.reset);
    document.removeEventListener('input', this.reset);
  }
}
