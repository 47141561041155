import { Module } from './module';
import { request } from './utils/request';
import type { MCFXTracker, PhoneNumberLease } from './declarations';

const clearSpecialCharsRegex = /[-/\\^$*+?.()|[\]{}\s]/g;

export class Calltracking extends Module {
  searchPattern: RegExp;
  deferedReplacements: Map<string, Set<Node>>;
  constructor(tracker: MCFXTracker) {
    super(tracker);
    this.tracker = tracker;
    this.deferedReplacements = new Map();

    if (this.tracker.configuration.mode === 'debug') {
      return;
    }

    if (this.tracker.configuration.calltracking) {
      this.init();
    }
  }

  init() {
    this.searchPattern = this.generateRegexForPhoneNumbers(
      Object.keys(this.tracker.configuration.calltracking)
    );

    const { channel, subchannel } = this.tracker.session.get('attribution');

    Object.entries(this.tracker.configuration.calltracking).forEach(([num, config]) => {
      if (false === config.usePool) {
        return;
      }
      // if we there is not a specific scope
      // the target all channels except direct
      if (config.poolScope === null && channel !== 'direct') {
        this.deferedReplacements.set(num, new Set());
        return;
      }

      // if we have a pool scope than only defer number replacement
      // when the channel or subchannel is in the scope
      if (
        config.poolScope &&
        (config.poolScope.includes(channel) ||
          config.poolScope.includes(subchannel) ||
          config.poolScope.includes('any'))
      ) {
        this.deferedReplacements.set(num, new Set());
      }
    });

    this.replaceNumbers(document.body);
    this.replaceDeferredNumbers();
    // .then(() => {
    //   const observer = new MutationObserver((mutationsList) => {
    //     for (let mutation of mutationsList) {
    //       replaceNumbers(mutation.target);
    //     }
    //   });
    //   });
  }

  generateRegexForPhoneNumbers(phoneNumbers) {
    const regexPatterns = phoneNumbers.map((phoneNumber) => {
      // Escape any special characters in the phone number
      const escapedNumber = phoneNumber.replace(clearSpecialCharsRegex, '');
      return escapedNumber.length === 10
        ? `(([0-9]{1,3})?${escapedNumber})`
        : `((${escapedNumber.slice(-13, -10)})?${escapedNumber.slice(-10)})`;
    });

    return new RegExp(regexPatterns.join('|'), 'g');
  }

  // Function to replace text content and href attributes
  replaceNumbers(node, skipDefered = false) {
    const { channel = '', subchannel = '' } =
      this.tracker.session.get('attribution').originalChannels ?? {};
    // Define the replacement keys in a single regular expression

    // Handle text nodes (nodeType === 3)
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent.replace(clearSpecialCharsRegex, '');
      const newText = text.replace(this.searchPattern, (match) => {
        const matchRef = this.getMatchReference(match);

        // if this is a number we need to defer to pool leasing first
        // we just return the text unless forcing skippingDefered
        if (this.deferedReplacements.has(matchRef) && skipDefered === false) {
          this.deferedReplacements.get(matchRef).add(node);
          return text;
        }

        const matchedNumber = this.tracker.configuration.calltracking[matchRef];
        const replaceAs =
          matchedNumber.replace.pool ??
          matchedNumber.replace[subchannel] ??
          matchedNumber.replace[channel] ??
          matchedNumber.replace.any;

        return this.formatPhoneNumber(replaceAs ?? text, matchedNumber.format);
      });

      // Only update the DOM if there's a change
      if (newText !== text) {
        node.textContent = newText;
      }
    }

    // Handle element nodes (nodeType === 1)
    else if (node.nodeType === Node.ELEMENT_NODE) {
      // Check if the element has an 'href' attribute with 'tel:' and replace it
      if (node.hasAttribute('href')) {
        const href = node.getAttribute('href');

        // If the href starts with 'tel:', apply the replacements
        if (href.startsWith('tel:')) {
          const newHref = href
            .replace(clearSpecialCharsRegex, '')
            .replace(this.searchPattern, (match) => {
              const matchRef = this.getMatchReference(match);

              if (this.deferedReplacements.has(matchRef) && skipDefered === false) {
                this.deferedReplacements.get(matchRef).add(node);
                return href;
              }

              const matchedNumber = this.tracker.configuration.calltracking[matchRef];

              return `${
                matchedNumber.replace.pool ??
                matchedNumber.replace[subchannel || 'direct'] ??
                matchedNumber.replace[channel] ??
                matchedNumber.replace.any ??
                href
              }`;
            });
          // Only update the DOM if there's a change
          if (newHref !== href) {
            node.setAttribute('href', newHref.startsWith('tel:') ? newHref : `tel:${newHref}`);
          }
        }
      }

      // Recursively traverse child nodes
      for (const child of node.childNodes) {
        this.replaceNumbers(child, skipDefered);
      }
    }
  }

  getMatchReference(match) {
    if (this.tracker.configuration.calltracking[match]) {
      return match;
    }

    return Object.keys(this.tracker.configuration.calltracking).find((number) => {
      if (match.length > number.length) {
        return match.includes(number);
      }
      return number.includes(match);
    });
  }

  /**
   * Formats a phone number according to a specified format string.
   *
   * The format string uses '#' as a placeholder for digits. Non-digit characters
   * in the format string are preserved in the output.
   *
   * @param number - The phone number to format. It should be a string containing digits.
   * @param format - The format string, where '#' represents a digit.
   * @returns The formatted phone number as a string.
   *
   * @example
   * // Returns "(123) 456-7890"
   * formatPhoneNumber("1234567890", "(###) ###-####");
   *
   * @example
   * // Returns "+1-234-567-8901"
   * formatPhoneNumber("+12345678901", "+#-###-###-####");
   *
   * @example
   * // Returns "123-4567"
   * formatPhoneNumber("1234567", "###-####");
   */
  formatPhoneNumber(number: string, format: string): string {
    // Remove the leading '+' and any non-digit characters
    const cleanedNumber = number.replace(/[^\d]/g, '');

    let formattedValue = '';
    let valueIndex = cleanedNumber.length - 1;

    for (let i = format.length - 1; i >= 0; i--) {
      if (format[i] === '#') {
        if (valueIndex >= 0) {
          formattedValue = cleanedNumber[valueIndex] + formattedValue;
          valueIndex--;
        } else {
          break;
        }
      } else {
        formattedValue = format[i] + formattedValue;
      }
    }

    return formattedValue;
  }

  async leasePoolNumbers(targets): Promise<PhoneNumberLease[]> {
    try {
      const url = `${this.tracker.configuration.agentUrl}/calltracking/pool-lease`;
      const {
        channel = '',
        subchannel = '',
        source,
        medium,
        ad,
      } = this.tracker.session.get('attribution');

      const leases = await request<PhoneNumberLease[]>(url).post({
        action: 'get-lease',
        siteId: this.tracker.configuration.siteId,
        visitorId: this.tracker.session.get('uid'),
        sessionId: this.tracker.session.get('sid'),
        targets,
        channel,
        subchannel,
        source,
        medium,
        ad,
      });

      return leases.filter((lease) => lease);
    } catch (_err) {
      return [];
    }
  }

  async replaceDeferredNumbers() {
    if (this.deferedReplacements.size === 0) {
      return;
    }
    // creates a targetId to "number config" map
    const poolMap = Array.from(this.deferedReplacements.keys()).reduce(
      (acc, number) => {
        acc[this.tracker.configuration.calltracking[number].targetId] = number;
        return acc;
      },
      {} as Record<string, string>
    );

    // need to replace this with a real api call
    const leasedNumbers = await this.leasePoolNumbers(Object.keys(poolMap));

    // loop through the leases and set leased number to the config.
    leasedNumbers.forEach(({ targetId, number }) => {
      this.tracker.configuration.calltracking[poolMap[targetId]].replace.pool = number as string;
    });

    // loop through our defe
    this.deferedReplacements.forEach((nodes) => {
      nodes.forEach((node) => {
        this.replaceNumbers(node, true);
      });
    });
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['calltracking']: Calltracking;
  }
}
